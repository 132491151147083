import {React} from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "/src/layout/default"
import Meta from "/src/components/Meta"
import * as style from "./about.module.css"

const AboutPage = ({location}) => {
  return (
    <Layout location={location}>
      <Meta title="当院について | 平安堂こころのクリニック" description="平安堂こころのクリニックは、津田沼駅の心療内科・精神科・児童精神科です。専門外来に、子どもの発達外来、強迫症外来を設けています。患者さまに安心して帰っていただけるこころ安らぐクリニックを目指しています。" >
        <meta name="format-detection" content="telephone=no" />
      </ Meta>
      <div className={style.container}>
        <h1 className="page-title"><span className="page-title-inner">当院について</span></h1>

        <p className={style.lead}>こころのつらさや悩みに寄り添い、<br />ともに歩んでいきます。</p>
        <div className={style.textBlock}>
          <p className={style.text}>2021年6月1日より、平安堂こころのクリニックを開院させて頂くことになりました。<br />子どもから大人まで幅広い年代の方が「来てよかった」と安心して帰っていただけるような、こころ安らぐクリニックにしていきたいと思います。</p>
          <p className={style.text}>成人のこころのご相談や心療内科の他、当院が専門分野として特に力を入れているのが「児童精神科の診療」「発達特性の悩み」「強迫症状での困りごと」です。<br />お電話でご予約の上、お気軽にご来院・ご相談ください。</p>
        </div>

        <div className={style.portrait}>
          <StaticImage className={style.portraitImage} src="../images/about/portrait-01.png" quality={100} alt="" />
          <h2 className={style.portraitText}><span className={style.position}>院長</span><span className={style.name}>久能 勝</span><span className={style.kana}>くのう まさる</span></h2>
        </div>
        <div className={style.bg}>
          <div className={style.info}>
            <div className={style.career}>
              <h3 className={style.infoTitle}>主な略歴</h3>
              <dl>
                <dt className="yaer">2001</dt>
                <dd>国際基督教大学卒業</dd>
              </dl>
              <dl>
                <dt className="yaer">2007</dt>
                <dd>北里大学医学部卒業</dd>
              </dl>
              <dl>
                <dt className="yaer">2009</dt>
                <dd>千葉大学医学部附属病院精神神経科に入局。木更津病院、千葉大学医学部附属病院子どものこころ診療部、千葉市立青葉病院児童精神科、千葉県こども病院、千葉大学子どものこころの発達教育研究センターなどで勤務。</dd>
              </dl>
              <dl>
                <dt className="yaer">2021</dt>
                <dd>平安堂こころのクリニック開設</dd>
              </dl>
            </div>
            
            <div className={style.infoCol}>
              <h3 className={style.infoTitle}>資格</h3>
              <ul>
                <li>医学博士</li>
                <li>精神保健指定医</li>
                <li>日本精神神経学会専門医・指導医</li>
                <li>日本児童青年精神医学会 認定医</li>
                <li>子どものこころ専門医</li>
                <li>日本医師会産業医</li>
                <li>公認心理師</li>
              </ul>
            </div>

            <div className={style.infoCol}>
              <h3 className={style.infoTitle}>所属学会</h3>
              <ul>
                <li>日本精神神経学会</li>
                <li>日本児童青年精神医学会</li>
                <li>日本認知・行動療法学会</li>
                <li>日本不安症学会</li>
                <li>日本家族療法学会</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={style.gallery}>
          <div className={style.galleryItem}>
            <StaticImage className={style.galleryImage} src="../images/about/image-01.jpg" quality={80} alt="" />
            <p className={style.galleryCaption}>受付</p>
          </div>
          <div className={style.galleryItem}>
            <StaticImage className={style.galleryImage} src="../images/about/image-02.jpg" quality={80} alt="" />
            <p className={style.galleryCaption}>待合室</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage