// extracted by mini-css-extract-plugin
export var container = "about-module--container--QdGxI";
export var lead = "about-module--lead---T47I";
export var textBlock = "about-module--text-block--xH-Zg";
export var text = "about-module--text--UeL-L";
export var portrait = "about-module--portrait--cH872";
export var portraitImage = "about-module--portrait-image--01eJe";
export var portraitText = "about-module--portrait-text--bYJRm";
export var position = "about-module--position--49CQ3";
export var name = "about-module--name--WiWvG";
export var kana = "about-module--kana--Fdws0";
export var bg = "about-module--bg--U0tbW";
export var info = "about-module--info--rXNZF";
export var infoTitle = "about-module--info-title--23EgZ";
export var career = "about-module--career--D9jBV";
export var infoCol = "about-module--info-col--Vw5zu";
export var gallery = "about-module--gallery--0QXSO";
export var galleryImage = "about-module--gallery-image--STgSR";
export var galleryCaption = "about-module--gallery-caption--DfZM-";
export var galleryItem = "about-module--gallery-item--3xA8i";